<template lang="">
  <div
    class="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "CardComponent"
};
</script>
<style lang=""></style>
