import store from "@/_store";
import msalService from "@/_auth/msalService";
import isTokenValid from "@/_utils/CheckTokenExpired";

const AccessTokenService = () => {
  let accessToken = store.getters.getAccessToken;

  if (isTokenValid(accessToken)) {
    return accessToken;
  } else {
    msalService.getToken();
    return store.getters.getAccessToken;
  }
};

export default AccessTokenService;
