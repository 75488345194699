<template>
  <nav
    class="bg-arcadis-orange-200 flex items-center justify-between px-4 py-2"
  >
    <div class="flex items-center space-x-4">
      <router-link
        :to="{ name: 'Home' }"
        class="text-arcadis-gray-800 font-bold text-xl"
        >TerraMirror API</router-link
      >
      <div v-for="route in routesWithMetaInfo" :key="route.component.name">
        <NavBarLink
          :routeName="route.name"
          :linkName="
            route.screenName !== undefined ? route.screenName : route.name
          "
          :adminAccess="
            route.meta && route.meta.adminAccess !== undefined
              ? route.meta.adminAccess
              : true
          "
        />
      </div>
    </div>
    <div v-if="isLoggedIn">
      <SignOutButton class="mr-4" />
    </div>
    <div v-else>
      <SignInButton />
    </div>
  </nav>
</template>

<script>
import SignInButton from "@/components/SignInButton.vue";
import SignOutButton from "@/components/SignOutButton.vue";
import { useStore } from "vuex";
import { computed } from "@vue/reactivity";
import NavBarLink from "@/components/NavbarLink.vue";
import { routesWithMetaInfo } from "@/_router";

export default {
  name: "NavbarComponent",
  components: { SignInButton, SignOutButton, NavBarLink },
  setup() {
    const store = useStore();

    const isLoggedIn = computed(() => {
      return store.getters.getLoggedInStatus;
    });

    const isAdmin = computed(() => {
      return store.getters.getAdminStatus;
    });

    return {
      isLoggedIn,
      isAdmin,
      routesWithMetaInfo
    };
  }
};
</script>

<style></style>
