const packages = {
  state: () => ({
    packages: {
      items: [],
      page: 0,
      pages: 0,
      size: 0,
      total: 0
    }
  }),
  getters: {
    getPackages: (state) => {
      return state.packages.items;
    },
    getPackageByIndex: (state) => (index) => {
      return state.packages.items[index];
    },
    getPackageByID: (state) => (id) => {
      return state.packages.items.filter((item) => item.packagecode_id === id);
    },
    getPackagePageSize: (state) => {
      return {
        page: state.packages.page,
        pages: state.packages.pages,
        size: state.packages.size,
        total: state.packages.total
      };
    }
  },
  actions: {
    savePackages({ commit }, payload) {
      commit("SET_PACKAGES", payload);
    },
    updatePackage({ commit }, payload) {
      commit("SET_PACKAGE", payload);
    }
  },
  mutations: {
    SET_PACKAGES(state, packages) {
      state.packages = packages;
    },
    SET_PACKAGE(state, payload) {
      const { index, data } = payload;
      state.packages.items[index] = data;
    }
  }
};

export default packages;
