<template lang="">
  <button class="btn mt-2 mb-2" @click="handleModal">Add parameter</button>
  <div v-if="nonRegulatedSubstances">
    <DataTable
      :paginated="false"
      :deletable="true"
      :columns="[
        {
          label: 'id',
          field: 'id',
          editable: false,
          type: 'text'
        },
        {
          label: 'parameter ID',
          field: 'parameter_id',
          editable: false,
          type: 'text'
        },
        {
          label: 'parameter',
          field: 'parameter',
          editable: false,
          type: 'text'
        }
      ]"
      :rows="nonRegulatedSubstances"
      @handleDelete="handleDelete"
    />
  </div>
  <div v-if="showModal">
    <ModalComponent>
      <div class="flex items-center">
        <label
          for="filter"
          class="block mr-4 mb-4 text-lm font-large font-bold text-arcadis-gray-800 dark:text-white whitespace-nowrap"
          >Search</label
        >
        <input
          type="inputType"
          id="filter"
          class="bg-gray-50 border border-arcadis-orange-300 text-arcadis-gray-800 text-lm rounded-lg focus:ring-arcadis-orange-700 focus:border-arcadis-orange-700 block w-full p-2.5 mb-4"
          placeholder="Search parameter"
          v-model="searchParam"
          @keyup.enter="handleSearch"
        />
        <button class="btn ml-4 mb-4" @click="handleSearch">Search</button>
      </div>

      <div class="flex items-center">
        <label
          for="selectParam"
          class="block text-lm mr-4 font-large font-bold text-arcadis-gray-800 bg-r dark:text-white whitespace-nowrap"
        >
          Select parameter
        </label>
        <select
          name=""
          id="selectParam"
          :class="{
            'bg-gray-100': !parameterError,
            'bg-red-300': parameterError,
            border: true,
            'border-arcadis-orange-300': !parameterError,
            'focus:ring-arcadis-orange-500': !parameterError,
            'focus:border-arcadis-orange-500': !parameterError,
            'border-red-800': parameterError,
            'focus:ring-red-800': parameterError,
            'focus:border-red-800': parameterError,
            'text-gray-900': !parameterError,
            'text-red-800': parameterError,
            'text-sm': true,
            italic: parameterError,
            'rounded-lg': true,
            block: true,
            'w-full': true,
            'p-2.5': true,
            'font-semibold': true,
            'disabled:bg-slate-400': true
          }"
          v-model="selectedParam"
        >
          <option value="">Select parameter</option>
          <option
            v-for="parameter in filteredParameters"
            :key="parameter.id"
            :value="parameter.id"
          >
            {{ parameter.Omschrijving }}
          </option>
        </select>
      </div>

      <div class="flex justify-between">
        <button class="btn mt-10" @click="handleSave">Add parameter</button>
        <button class="btn mt-10" @click="handleModal">Cancel</button>
      </div>
    </ModalComponent>
  </div>
</template>
<script>
import DataTable from "@/components/DataTable.vue";
import NonRegulatedSubsServices from "@/_services/NonRegulatedSubsServices";
import ModalComponent from "@/components/ModalComponent.vue";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import ParameterServices from "@/_services/ParameterServices";

export default {
  name: "NonRegulatedSubsComponent",
  components: { DataTable, ModalComponent },
  setup() {
    const store = useStore();
    const showModal = ref(false);
    const searchParam = ref("");
    const searchParamError = ref(false);
    const parameterError = ref(false);
    const selectedParam = ref("");

    const handleModal = () => {
      showModal.value = !showModal.value;
    };

    const loadingState = computed(() => {
      return store.getters.getLoadingState;
    });

    const nonRegulatedSubsService = NonRegulatedSubsServices();
    const parameterService = ParameterServices();

    try {
      nonRegulatedSubsService.ALL_SUBS();
    } catch (err) {
      console.log(err);
    }

    const nonRegulatedSubstances = computed(() => {
      return store.getters.getNonRegulatedSub;
    });

    const handleSearch = () => {
      if (searchParam.value) {
        parameterService.ALL_PARAMS(null, null, searchParam.value.trim());
      } else {
        searchParamError.value = true;
      }
    };

    const handleSave = () => {
      const payload = {
        parameter_id: selectedParam.value
      };
      nonRegulatedSubsService.ADD_SUB(payload);
      showModal.value = !showModal.value;
    };
    const parameters = computed(() => {
      return store.getters.getParameters;
    });

    const filteredParameters = computed(() => {
      const arrayOfSelectedParameters = [...nonRegulatedSubstances.value].map(
        (obj) => obj.parameter_id
      );
      return parameters.value.filter(
        (obj) => !arrayOfSelectedParameters.includes(obj.id)
      );
    });

    const handleDelete = (value) => {
      console.log(value);
      try {
        nonRegulatedSubsService.DELETE_SUB(value.data.id, value.index);
      } catch (err) {
        console.log(err);
      } finally {
        store.dispatch("changeEditMode", false);
      }
    };

    return {
      loadingState,
      nonRegulatedSubstances,
      handleModal,
      handleSearch,
      filteredParameters,
      parameterError,
      searchParam,
      selectedParam,
      handleSave,
      showModal,
      handleDelete
    };
  }
};
</script>
<style lang=""></style>
