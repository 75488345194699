function isZipFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onloadend = function () {
      const arr = new Uint8Array(reader.result).subarray(0, 4);
      const header = Array.from(arr)
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");

      resolve(header === "504b0304"); // Check if the file's header matches the ZIP signature
    };

    reader.readAsArrayBuffer(file.slice(0, 4));
  });
}

export default isZipFile;
