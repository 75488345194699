const custom_tests = {
  state: () => ({
    custom_tests: [
      {
        id: "",
        custom_test_name: "",
        custom_test_name_long: "",
        exec_on_startup: "",
        inactive: "",
        location_bound: "",
        valid_from: "",
        valid_to: "",
        custom_assessment_levels: []
      }
    ]
  }),
  getters: {
    getCustomtests: (state) => {
      return state.custom_tests;
    },
    getCustomTestsGenericData: (state) => {
      return state.custom_tests.map((customTest) => {
        return {
          id: customTest.id,
          custom_test_name: customTest.custom_test_name,
          custom_test_name_long: customTest.custom_test_name_long,
          exec_on_startup: customTest.exec_on_startup,
          inactive: customTest.inactive,
          location_bound: customTest.location_bound,
          valid_from: customTest.valid_from,
          valid_to: customTest.valid_to
        };
      });
    }
  },
  actions: {
    saveCustomtests({ commit }, payload) {
      commit("setCustomtests", payload);
    },
    addCustomtest({ commit }, payload) {
      commit("ADDCUSTOMTEST", payload);
    }
  },
  mutations: {
    setCustomtests(state, payload) {
      state.custom_tests = payload;
    },
    ADDCUSTOMTEST(state, payload) {
      state.custom_tests.push(...payload);
    }
  }
};

export default custom_tests;
