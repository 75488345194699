<template>
  <div v-if="loadingState"><LoadingComponent /></div>
  <div class="flex justify-center items-center mt-4">
    <div class="grid grid-cols-2 gap-4">
      <div class="p-4 font-bold">Projectnummer:</div>
      <div>
        <input
          type="text"
          class="bg-gray-50 border border-arcadis-orange-300 text-gray-900 text-lm rounded-lg focus:ring-arcadis-orange-500 focus:border-arcadis-orange-500 block p-2.5"
          v-model="projectNumber"
          placeholder="projectnummer"
          @keyup.enter="handleLoadProject"
          @keypress="updateProjectNumber"
        />
      </div>

      <div class="p-4 font-bold">Licentie:</div>
      <div>
        <select
          v-model="selectedLicense"
          name="license"
          id="licenseSelected"
          class="bg-gray-50 border border-arcadis-orange-300 text-gray-900 text-lm rounded-lg focus:ring-arcadis-orange-500 focus:border-arcadis-orange-500 block p-2.5"
        >
          <option
            v-for="license in licensesList"
            :key="license.license"
            :value="license.license"
          >
            {{ license.license }} - {{ license.description }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="flex items-center justify-center w-auto">
    <CardComponent v-if="projectData.int">
      <div class="grid grid-cols-1 gap-4 w-max">
        <div
          v-for="(value, key) in projectData"
          :key="key"
          :value="value"
          class="grid grid-cols-2 gap-4"
        >
          <label :for="key" class="col-span-1 font-semibold">{{ key }}:</label>
          <p
            :id="key"
            class="border border-gray-300 px-4 py-2 col-span-1 w-max"
          >
            {{ displayValue(key, lookupValue(value)) }}
          </p>
        </div>
      </div>
    </CardComponent>
  </div>

  <div class="col-span-4 flex justify-center mt-4">
    <button class="btn mx-2 text-sm" @click="handleLoadProject">
      Check Terramirror
    </button>
    <button class="btn mx-2 text-sm" @click="downloadTerraindex">
      Download JSON
    </button>
    <button class="btn mx-2 text-sm" @click="handleUpdate">Force update</button>
    <div class="btn mx-2 text-sm">
      <FileComponent @fileSelected="handleFileSelected" />
    </div>
    <div v-if="isAdmin">
      <button class="btn mx-2 text-sm" @click="handleDelete">
        Delete project
      </button>
    </div>
  </div>

  <div v-if="projectNotFound.length">
    <CardComponent>
      <h2 class="italic mt-4 font-bold">Project incorrect</h2>
    </CardComponent>
  </div>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";
import ProjectServices from "@/_services/ProjectServices";
import { useStore } from "vuex";
import isZipFile from "@/_utils/CheckZipFile";
import FileComponent from "@/components/FileLoader.vue";
import CardComponent from "@/components/CardComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import formatReadableDateTime from "@/_utils/FormatDateTimeString";

export default {
  name: "ProjectsComponent",
  components: { FileComponent, CardComponent, LoadingComponent },
  setup() {
    const store = useStore();
    const projectNotFound = ref("");
    const projectService = ProjectServices();

    const displayValue = (key, value) => {
      if ((key == "PrDateLastChanged") | (key == "LastImport")) {
        return formatReadableDateTime(value);
      } else return value;
    };
    const isAdmin = computed(() => {
      return store.getters.getAdminStatus;
    });

    const loadingState = computed(() => {
      return store.getters.getLoadingState;
    });

    const selectedLicense = ref(853);

    const computedSelectedLicense = computed({
      get: () => selectedLicense.value,
      set: (newValue) => {
        selectedLicense.value = newValue;
        // Additional actions you might want to perform on set
      }
    });

    const selectedAppcode = computed(() => {
      return store.getters.getAppcode(selectedLicense.value);
    });

    const licensesList = computed(() => {
      return store.getters.getLicenses;
    });

    const handleProjectNumber = (value) => {
      store.dispatch("saveProjectNumber", value.target.value);
    };

    const true_false = computed(() => {
      return store.getters.getTrueFalse;
    });

    const lookupValue = (value) => {
      if (typeof value === "boolean") {
        const foundItem = true_false.value.find(
          (item) => item.value === value
        ).label;

        return foundItem;
      } else {
        return value;
      }
    };

    const projectLicense = computed(() => {
      return store.getters.getLicense;
    });

    const projectData = computed(() => {
      return store.getters.getProjectData;
    });

    const projectNumberStore = computed(() => {
      return store.getters.getProjectNumber;
    });

    const projectNumber = ref("");

    const handleUpdate = async () => {
      try {
        await projectService.UPDATE(
          { PrCode: projectNumber.value },
          selectedLicense.value
        );
      } catch (err) {
        console.log(err);
      }
    };

    watch(projectLicense, (newValue) => {
      if (newValue) {
        computedSelectedLicense.value = newValue;
      }
    });

    watch(projectNumber, (newValue) => {
      store.commit("setProjectNumber", newValue);
    });

    const downloadTerraindex = async () => {
      if (projectNumber.value) {
        try {
          await projectService.DOWNLOAD_TERRAINDEX(
            {
              PrCode: projectNumber.value
            },
            selectedLicense.value,
            selectedAppcode.value
          );
        } catch (err) {
          console.error(err);
        }
      }
    };

    const handleLoadProject = async () => {
      if (projectNumber.value) {
        const projectService = ProjectServices();
        try {
          await projectService
            .METADATA(
              {
                PrCode: projectNumber.value
              },
              false
            )
            .then(() => {
              console.log("update succeeded");
            });
        } catch (err) {
          if (err.message === "Request failed with status code 404") {
            console.log("Project not found");
            projectNotFound.value = "Project not found";
          } else {
            console.error("An error occurred:", err);
          }
        }
      }
    };

    const handleDelete = async () => {
      if (projectNumber.value) {
        if (window.confirm("Are you sure you want to delete this project")) {
          const projectService = ProjectServices();
          try {
            await projectService.DELETE_PROJECT(projectData.value.id, true);
          } catch (err) {
            console.error(err);
          }
        }
      }
    };

    const handleFileSelected = async () => {
      const file = store.getters.getFile;

      if (isZipFile(file)) {
        const formData = new FormData();
        formData.append("file", file);

        try {
          const response = projectService.UPLOAD_FILE(
            projectData.value.id,
            formData
          );

          // Handle the response from the API
          console.log("API Response:", response);
        } catch (error) {
          // Handle any errors that occur during the API request
          console.error("API Error:", error);
        }
      } else console.error("not a valid ZIP-file");

      store.dispatch("deleteFile");
    };

    onMounted(() => {
      if (!projectNumber.value) {
        projectNumber.value = projectNumberStore.value;
      }

      if (projectLicense.value) {
        selectedLicense.value = projectLicense.value;
      }
    });

    return {
      projectNumber,
      projectData,
      handleProjectNumber,
      handleLoadProject,
      handleUpdate,
      projectNotFound,
      downloadTerraindex,
      handleFileSelected,
      lookupValue,
      licensesList,
      selectedLicense,
      selectedAppcode,
      loadingState,
      isAdmin,
      handleDelete,
      projectNumberStore,
      projectLicense,
      displayValue
    };
  }
};
</script>

<style></style>
