export default function formatReadableDateTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short"
  };

  return dateTime.toLocaleString("nl-NL", options);
}
