const constants_library = {
  state: () => ({
    true_false: [
      { value: true, label: "Ja" },
      { value: false, label: "Nee" },
      { value: null, label: null }
    ],
    licenses: [
      {
        license: 853,
        appcode: 32,
        description: "Arcadis main license"
      },
      {
        license: 1218,
        appcode: 32,
        description: "GROW"
      },
      {
        license: 1237,
        appcode: 116,
        description: "Testing environment"
      }
    ]
  }),
  getters: {
    getTrueFalse: (state) => {
      return state.true_false;
    },
    getLicenses: (state) => {
      return state.licenses; //.map((item) => item.license);
    },
    getAppcode: (state) => (license) => {
      const item = state.licenses.find((item) => item.license === license);
      return item.appcode;
    }
  }
};

export default constants_library;
