<template>
  <button class="btn" @click="login">Login</button>
</template>

<script>
import msalService from "../_auth/msalService.js";

export default {
  name: "SignInButton",
  setup() {
    const login = async () => {
      await msalService.login().catch((e) => console.error(e));
    };

    return {
      login
    };
  }
};
</script>

<style></style>
