const application_state = {
  state: () => ({
    editMode: false,
    parametersFiltered: false,
    loadingState: false,
    packageFiltered: false
  }),
  getters: {
    getEditMode: (state) => {
      return state.editMode;
    },
    getParameterFiltered: (state) => {
      return state.parametersFiltered;
    },
    getLoadingState: (state) => {
      return state.loadingState;
    },
    getPackageFiltered: (state) => {
      return state.packageFiltered;
    }
  },
  actions: {
    changeEditMode({ commit }, editMode) {
      commit("SET_EDITMODE", editMode);
    },
    changeParameterFilterMode({ commit }, filterMode) {
      commit("SET_PARAMETERFILTERMODE", filterMode);
    },

    changeLoadingState({ commit }) {
      commit("CHANGE_LOADINGSTATE");
    },
    changePackageFilterMode({ commit }) {
      commit("CHANGE_PACKAGEFILTER");
    }
  },
  mutations: {
    SET_EDITMODE(state, editMode) {
      state.editMode = editMode;
    },
    SET_PARAMETERFILTERMODE(state, filterMode) {
      state.parametersFiltered = filterMode;
    },
    CHANGE_LOADINGSTATE(state) {
      state.loadingState = !state.loadingState;
    },
    CHANGE_PACKAGEFILTER(state) {
      state.packageFiltered = !state.packageFiltered;
    }
  }
};

export default application_state;
