<template>
  <transition name="modal">
    <div class="fixed inset-0 flex items-center justify-center z-50">
      <div class="bg-white border border-arcadis-gray-800 rounded-lg p-8">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalComponent"
};
</script>

<style scoped>
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>
