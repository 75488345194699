import CustomTestsLevelServices from "@/_services/CustomTestLevelServices";
import store from "@/_store";

const custom_tests_levels = {
  state: () => ({
    custom_test_id: "",
    custom_test_levels: [],
    movedRows: [],
    sortColumn: "CompoundOrder",
    sortDirection: "asc"
  }),
  getters: {
    getCustomTestId: (state) => {
      return state.custom_test_id;
    },
    getCustomTestLevels: (state) => {
      const column = state.sortColumn;
      const direction = state.sortDirection === "asc" ? 1 : -1;

      if (state.custom_test_levels.length) {
        return state.custom_test_levels.slice().sort((a, b) => {
          if (a[column] < b[column]) return -1 * direction;
          if (a[column] > b[column]) return 1 * direction;
          return 0;
        });
      } else {
        return state.custom_test_levels;
      }
    },
    getCustomTestLevelByIndex: (state) => (index) => {
      return state.custom_test_levels[index];
    },
    getMovedRows: (state) => {
      return state.movedRows;
    }
  },

  actions: {
    saveCustomTestId({ commit }, customTestId) {
      commit("setCustomTestId", customTestId);
    },
    saveCustomTestLevels({ commit }, customTestLevels) {
      commit("setCustomTestLevels", customTestLevels);
    },
    updateCustomTestLevels({ commit }, customTestLevels) {
      commit("setCustomTestLevel", customTestLevels);
    },
    saveCustomTestLevel({ commit }, customTestLevel) {
      commit("addCustomTestLevel", customTestLevel);
    },
    updateCustomTestLevelDB({ commit }, customTestLevel) {
      commit("setCustomTestLevelFromDB", customTestLevel);
    },
    deleteCustomTestLevel({ commit }, customTestLevel) {
      commit("deleteCustomTestLevel", customTestLevel);
    },
    moveRow({ commit }, data) {
      commit("MOVE_ROW", data);
    }
  },
  mutations: {
    setCustomTestId(state, customTestId) {
      state.custom_test_id = customTestId;
    },
    setCustomTestLevels(state, customTestLevels) {
      state.custom_test_levels = customTestLevels;
    },
    setCustomTestLevel(state, payload) {
      const { index, data, updated } = payload;

      if (!updated) {
        if (index !== data.originalIndex) {
          state.custom_test_levels.splice(index, 1);
          state.custom_test_levels.splice(data.originalIndex, 0, data);
        } else {
          state.custom_test_levels[index] = data;
        }
      }

      if (updated) {
        if (index !== data.originalIndex) {
          state.custom_test_levels.splice(index, 1);
          state.custom_test_levels.splice(data.index, 0, data);

          state.custom_test_levels.sort(
            (a, b) => a.CompoundOrder - b.CompoundOrder
          );
          state.custom_test_levels.forEach((level, index) => {
            level.CompoundOrder = index + 1;
          });

          const customTestLevelService = CustomTestsLevelServices(
            store.getters.getAccessToken
          );
          const payload = state.custom_test_levels.map((item) => {
            return { id: item.id, CompoundOrder: item.CompoundOrder };
          });

          customTestLevelService.UPDATE_SORTING(state.getCustomTestId, payload);
        } else {
          state.custom_test_levels[index] = data;
        }
      }
    },
    setCustomTestLevelFromDB(state, payload) {
      const customTestLevel = state.custom_test_levels.find(
        (level) => level.id === payload.id
      );

      if (customTestLevel) {
        Object.assign(customTestLevel, payload);
      }
    },
    addCustomTestLevel(state, payload) {
      state.custom_test_levels.push(...payload);
    },
    deleteCustomTestLevel(state, payload) {
      const { index } = payload;
      state.custom_test_levels.splice(index, 1);
    },
    MOVE_ROW(state, { index, direction }) {
      const newIndex = direction === "up" ? index - 1 : index + 1;

      if (newIndex >= 0 && newIndex < state.custom_test_levels.length) {
        const movedItem = state.custom_test_levels[index];
        state.custom_test_levels.splice(index, 1);
        state.custom_test_levels.splice(newIndex, 0, movedItem);
        state.custom_test_levels.forEach((item, i) => {
          item.CompoundOrder = i + 1;
        });
      }
    }
  }
};

export default custom_tests_levels;
