import makeRequest from "@/_services/ApiService";
import createRequestOptions from "./RequestOptions";
import store from "@/_store";

const CustomTestsLevelServices = () => {
  const requestOption = createRequestOptions();

  return {
    async GET_LEVELS(id) {
      store.dispatch("changeLoadingState");
      await makeRequest(requestOption.GET(`v2/custom_tests/${id}/levels`))
        .then((response) => {
          store.dispatch("saveCustomTestLevels", response);
          store.dispatch("changeLoadingState");
        })
        .catch((err) => {
          store.dispatch("changeLoadingState");
          console.error(err);
        });
    },
    async UPDATE(custom_test_id, custom_test_level_id, payload) {
      store.dispatch("changeLoadingState");
      await makeRequest(
        requestOption.PATCH(
          `v2/custom_tests/${custom_test_id}/levels/${custom_test_level_id}/update`,
          payload
        )
      )
        .then((resp) => {
          store.dispatch("updateCustomTestLevelDB", resp);
          store.dispatch("changeLoadingState");
        })
        .catch((err) => {
          console.error(err);
          store.dispatch("changeLoadingState");
        });
    },
    async UPDATE_SORTING(custom_test_id, payload) {
      store.dispatch("changeLoadingState");
      await makeRequest(
        requestOption.PATCH(
          `v2/custom_tests/${custom_test_id}/levels/update_sorting`,
          payload
        )
      )
        .then((resp) => {
          console.log(resp);
          store.dispatch("changeLoadingState");
        })
        .catch((err) => {
          console.error(err);
          store.dispatch("changeLoadingState");
        });
    },
    async ADD(custom_test_id, payload) {
      store.dispatch("changeLoadingState");
      await makeRequest(
        requestOption.POST(
          `v2/custom_tests/${custom_test_id}/levels/add`,
          payload
        )
      )
        .then((response) => {
          store.dispatch("saveCustomTestLevel", response);
          store.dispatch("changeLoadingState");
        })

        .catch((err) => {
          console.error(err);
          store.dispatch("changeLoadingState");
        });
    },
    async DELETE(custom_test_id, custom_test_level_id, payload) {
      store.dispatch("changeLoadingState");
      await makeRequest(
        requestOption.DELETE(
          `v2/custom_tests/${custom_test_id}/levels/${custom_test_level_id}/delete`
        )
      )
        .then(() => {
          store.dispatch("deleteCustomTestLevel", payload);
          store.dispatch("changeLoadingState");
        })
        .catch((err) => {
          console.error(err);
          store.dispatch("changeLoadingState");
        });
    }
  };
};

export default CustomTestsLevelServices;
