import { createApp } from "vue";
import App from "./App.vue";
import router from "./_router";
import store from "./_store";
import "@/assets/css/tailwind.scss";
//import "./style.css";

const storedState = sessionStorage.getItem("TerraApiStore");

if (storedState) {
  store.replaceState(JSON.parse(storedState));
}

document.title = process.env.VUE_APP_TITLE;

createApp(App).use(router).use(store).mount("#app");
