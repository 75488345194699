import isTokenValid from "@/_utils/CheckTokenExpired";
import decodeToken from "@/_utils/DecodeToken";
import createTimeStamp from "@/_utils/CreateTimeStamp";
import UserService from "@/_services/UserServices";

const user = {
  state: () => ({
    user: {
      name: "",
      logged_in: false,
      account: "",
      accessToken: "",
      isAdmin: false,
      roles: null
    },
    users: [],
    editorRoles: ["Admin", "Updater"]
  }),
  getters: {
    getAccessToken: (state) => {
      return state.user.account.accessToken;
    },
    getIdToken: (state) => {
      return state.user.account.idToken;
    },
    getUserName: (state) => {
      return state.user.name;
    },
    getLoggedInStatus: (state) => {
      return state.user.logged_in;
    },
    getAccount: (state) => {
      return state.user.account;
    },
    getRole: (state) => {
      return state.user.roles;
    },
    getAdminStatus: (state) => {
      return state.user.isAdmin;
    },
    getIsEditor: (state) => {
      return state.editorRoles.some((item) => state.user.roles.includes(item));
    },
    getIsTokenValid: (state) => {
      return isTokenValid(state.user.account.accessToken);
    },
    getRemainingTimeToken: (state) => {
      let expiryTime = decodeToken(state.user.account.accessToken).exp;
      let currentTimeStamp = createTimeStamp();
      return expiryTime - currentTimeStamp;
    },
    getTokenExp: (state) => {
      return state.user.account.expiresOn;
    },
    getAllUsers: (state) => {
      return state.users;
    }
  },
  actions: {
    saveAccount({ commit }, account) {
      commit("setUserAccount", account);
    },
    saveLogInStatus({ commit }, status) {
      commit("setUserLoggedIn", status);
    },
    saveAccessToken({ commit }, token) {
      commit("setAccessToken", token);
    },
    async fetchUsers({ commit }) {
      const userService = UserService();
      const users = await userService.GET_ALL();

      commit("SET_USERS", users);
    }
  },
  mutations: {
    SET_USERS(state, users) {
      state.users = users;
    },
    setAccessToken(state, token) {
      state.user.accessToken = token;
    },
    setUserName(state, name) {
      state.user.name = name;
    },
    setUserLoggedIn(state, logged_in) {
      state.user.logged_in = logged_in;
    },
    setUserAccount(state, account) {
      state.user.account = account;
    },
    SETUSERROLE(state, role) {
      state.user.roles = role;
    },
    SETADMIN(state, admin) {
      state.user.isAdmin = admin;
    }
  }
};

export default user;
