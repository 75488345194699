const api_definition = {
  state: () => ({
    api_definition: ""
  }),
  getters: {
    getApiDefinition: (state) => {
      return state.api_definition;
    }
  },
  actions: {
    saveApiDefinition({ commit }, api_definition) {
      commit("setApiDefinition", api_definition);
    }
  },
  mutations: {
    setApiDefinition(state, api_definition) {
      state.api_definition = api_definition;
    }
  }
};

export default api_definition;
