import makeRequest from "@/_services/ApiService";
import createRequestOptions from "./RequestOptions";
import store from "@/_store";

const NonRegulatedSubsServices = () => {
  const requestOption = createRequestOptions();

  return {
    async ALL_SUBS() {
      store.dispatch("changeLoadingState");
      await makeRequest(requestOption.GET("v2/non_regulated_subs/all"))
        .then((resp) => {
          console.log(resp);
          store.dispatch("saveNonRegulatedSubs", resp);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(store.dispatch("changeLoadingState"));
    },
    async ADD_SUB(payload) {
      store.dispatch("changeLoadingState");
      console.log(payload);
      await makeRequest(requestOption.POST("v2/non_regulated_subs/", payload))
        .then((resp) => {
          store.dispatch("addNonRegulatedSubs", resp);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(store.dispatch("changeLoadingState"));
    },
    async DELETE_SUB(id, index) {
      store.dispatch("changeLoadingState");
      await makeRequest(
        requestOption.DELETE(`v2/non_regulated_subs/${id}/delete/`)
      )
        .then((resp) => {
          console.log(resp);
          store.dispatch("deleteNonRegulatedSubs", index);
        })
        .catch((err) => console.error(err))
        .finally(store.dispatch("changeLoadingState"));
    }
  };
};

export default NonRegulatedSubsServices;
