import makeRequest from "@/_services/ApiService";
import createRequestOptions from "./RequestOptions";
import store from "@/_store";

const UnitServices = () => {
  const requestOption = createRequestOptions();

  return {
    async GET_ALL() {
      try {
        await makeRequest(requestOption.GET("v2/units/")).then((response) => {
          store.dispatch("saveUnits", response);
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  };
};

export default UnitServices;
