<template lang="">
  <div v-if="loadingState"><LoadingComponent /></div>
  <div v-if="customTests.length">
    <label
      for="custom_tests"
      class="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white disabled:bg-slate-400"
      >Select a custom test</label
    >
    <select
      v-model="selectedTestId"
      id="custom_tests"
      :class="[
        'bg-gray-100',
        'border',
        'border-arcadis-orange-300',
        'text-gray-900',
        'text-sm',
        'rounded-lg',
        'focus:ring-arcadis-orange-500',
        'focus:border-arcadis-orange-500',
        'block',
        'w-full',
        'p-2.5',
        'font-semibold',
        { 'mt-2': editMode, 'mb-2': !editMode },
        'text-sm',
        'font-medium',
        'text-gray-900',
        'dark:text-white',
        { 'disabled:bg-slate-400': editMode }
      ]"
    >
      <option
        v-for="custom_test in customTests"
        :key="custom_test.id"
        :value="custom_test.id"
        :disabled="editMode"
      >
        {{ custom_test.custom_test_name }}
      </option>
    </select>
  </div>
  <div v-if="selectedTestId">
    <button class="btn mt-2 mb-2" @click="handleModal">Add parameters</button>
  </div>
  <ModalComponent v-if="showModal">
    <div class="flex items-center">
      <label
        for="filter"
        class="block mr-4 mb-4 text-lm font-large font-bold text-arcadis-gray-800 dark:text-white whitespace-nowrap"
        >Search</label
      >
      <input
        type="inputType"
        id="filter"
        class="bg-gray-50 border border-arcadis-orange-300 text-arcadis-gray-800 text-lm rounded-lg focus:ring-arcadis-orange-700 focus:border-arcadis-orange-700 block w-full p-2.5 mb-4"
        placeholder="Search parameter"
        v-model="searchParam"
        @keyup.enter="handleSearch"
      />
      <button class="btn ml-4 mb-4" @click="handleSearch">Search</button>
    </div>
    <div class="flex items-center">
      <label
        for="selectParam"
        class="block text-lm mr-4 font-large font-bold text-arcadis-gray-800 bg-r dark:text-white whitespace-nowrap"
      >
        Select parameter
      </label>
      <select
        name=""
        id="selectParam"
        :class="{
          'bg-gray-100': !parameterError,
          'bg-red-300': parameterError,
          border: true,
          'border-arcadis-orange-300': !parameterError,
          'focus:ring-arcadis-orange-500': !parameterError,
          'focus:border-arcadis-orange-500': !parameterError,
          'border-red-800': parameterError,
          'focus:ring-red-800': parameterError,
          'focus:border-red-800': parameterError,
          'text-gray-900': !parameterError,
          'text-red-800': parameterError,
          'text-sm': true,
          italic: parameterError,
          'rounded-lg': true,
          block: true,
          'w-full': true,
          'p-2.5': true,
          'font-semibold': true,
          'disabled:bg-slate-400': true
        }"
        v-model="selectedParam"
      >
        <option value="">Select parameter</option>
        <option
          v-for="parameter in filteredParameters"
          :key="parameter.id"
          :value="parameter.id"
        >
          {{ parameter.Omschrijving }}
        </option>
      </select>
    </div>

    <div class="flex items-center">
      <label
        for="L1"
        class="block mr-4 mt-4 mb-4 text-lm font-large font-bold text-arcadis-gray-800 dark:text-white whitespace-nowrap"
        >L1</label
      >
      <input
        type="inputType"
        id="L1"
        v-model="L1"
        :class="[
          'border',
          'disabled:bg-arcadis-gray-600',
          'border-arcadis-orange-300',
          'text-arcadis-gray-800',
          'text-lm',
          'rounded-lg',
          'focus:ring-arcadis-orange-700',
          'focus:border-arcadis-orange-700',
          'block',
          'w-full',
          'p-2.5',
          'mt-4',
          'mb-4',
          floatErrors[0].value
            ? 'border-red-500 bg-red-300'
            : 'border-arcadis-orange-300 bg-gray-50'
        ]"
        placeholder="L1"
        :disabled="disabledInputbox"
      />
    </div>

    <div class="flex items-center">
      <label
        for="L2"
        class="block mr-4 mt-4 mb-4 text-lm font-large font-bold text-arcadis-gray-800 dark:text-white whitespace-nowrap"
        >L2</label
      >
      <input
        type="inputType"
        id="L2"
        v-model="L2"
        :class="[
          'border',
          'disabled:bg-arcadis-gray-600',
          'border-arcadis-orange-300',
          'text-arcadis-gray-800',
          'text-lm',
          'rounded-lg',
          'focus:ring-arcadis-orange-700',
          'focus:border-arcadis-orange-700',
          'block',
          'w-full',
          'p-2.5',
          'mt-4',
          'mb-4',
          floatErrors[1].value
            ? 'border-red-500 bg-red-300'
            : 'border-arcadis-orange-300 bg-gray-50'
        ]"
        placeholder="L2"
        :disabled="disabledInputbox"
      />
    </div>

    <div class="flex items-center">
      <label
        for="L3"
        class="block mr-4 mt-4 mb-4 text-lm font-large font-bold text-arcadis-gray-800 dark:text-white whitespace-nowrap"
        >L3</label
      >
      <input
        type="inputType"
        id="L3"
        v-model="L3"
        :class="[
          'border',
          'disabled:bg-arcadis-gray-600',
          'border-arcadis-orange-300',
          'text-arcadis-gray-800',
          'text-lm',
          'rounded-lg',
          'focus:ring-arcadis-orange-700',
          'focus:border-arcadis-orange-700',
          'block',
          'w-full',
          'p-2.5',
          'mt-4',
          'mb-4',
          floatErrors[2].value
            ? 'border-red-500 bg-red-300'
            : 'border-arcadis-orange-300 bg-gray-50'
        ]"
        placeholder="L3"
        :disabled="disabledInputbox"
      />
    </div>

    <div class="flex items-center">
      <label
        for="L4"
        class="block mr-4 mt-4 mb-4 text-lm font-large font-bold text-arcadis-gray-800 dark:text-white whitespace-nowrap"
        >L4</label
      >
      <input
        type="inputType"
        id="L4"
        :class="[
          'border',
          'disabled:bg-arcadis-gray-600',
          'border-arcadis-orange-300',
          'text-arcadis-gray-800',
          'text-lm',
          'rounded-lg',
          'focus:ring-arcadis-orange-700',
          'focus:border-arcadis-orange-700',
          'block',
          'w-full',
          'p-2.5',
          'mt-4',
          'mb-4',
          floatErrors[3].value
            ? 'border-red-500 bg-red-300'
            : 'border-arcadis-orange-300 bg-gray-50'
        ]"
        placeholder="L4"
        :disabled="disabledInputbox"
      />
    </div>

    <div class="flex items-center">
      <label
        for="L5"
        class="block mr-4 mt-4 mb-4 text-lm font-large font-bold text-arcadis-gray-800 dark:text-white whitespace-nowrap"
        >L5</label
      >
      <input
        type="inputType"
        id="L5"
        v-model="L5"
        :class="[
          'border',
          'disabled:bg-arcadis-gray-600',
          'border-arcadis-orange-300',
          'text-arcadis-gray-800',
          'text-lm',
          'rounded-lg',
          'focus:ring-arcadis-orange-700',
          'focus:border-arcadis-orange-700',
          'block',
          'w-full',
          'p-2.5',
          'mt-4',
          'mb-4',
          floatErrors[4].value
            ? 'border-red-500 bg-red-300'
            : 'border-arcadis-orange-300 bg-gray-50'
        ]"
        placeholder="L5"
        :disabled="disabledInputbox"
      />
    </div>

    <div class="flex items-center">
      <label
        for="L6"
        class="block mr-4 mt-4 mb-4 text-lm font-large font-bold text-arcadis-gray-800 dark:text-white whitespace-nowrap"
        >L6</label
      >
      <input
        type="inputType"
        id="L6"
        v-model="L6"
        :class="[
          'border',
          'disabled:bg-arcadis-gray-600',
          'border-arcadis-orange-300',
          'text-arcadis-gray-800',
          'text-lm',
          'rounded-lg',
          'focus:ring-arcadis-orange-700',
          'focus:border-arcadis-orange-700',
          'block',
          'w-full',
          'p-2.5',
          'mt-4',
          'mb-4',
          floatErrors[5].value
            ? 'border-red-500 bg-red-300'
            : 'border-arcadis-orange-300 bg-gray-50'
        ]"
        placeholder="L6"
        :disabled="disabledInputbox"
      />
    </div>

    <div class="flex items-center">
      <label
        for="selectUnit"
        class="block text-lm mr-4 font-large font-bold text-arcadis-gray-800 dark:text-white whitespace-nowrap"
      >
        Select unit
      </label>
      <select
        name=""
        id="selectUnit"
        class="bg-gray-100 border border-arcadis-orange-300 text-gray-900 text-sm rounded-lg focus:ring-arcadis-orange-500 focus:border-arcadis-orange-500 block w-full p-2.5 font-semibold"
        v-model="selectedUnit"
      >
        <option
          v-for="unit in sortedUnits"
          :key="unit.AsUnitCode"
          :value="unit.AsUnitCode"
        >
          {{ unit.Description }}
        </option>
      </select>
    </div>

    <div class="flex items-center">
      <label
        for="SortOrder"
        class="block mr-4 mt-4 mb-4 text-lm font-large font-bold text-arcadis-gray-800 dark:text-white whitespace-nowrap"
        >Sorteervolgorde</label
      >
      <input
        type="inputType"
        id="SortOrder"
        v-model="CompoundOrder"
        class="bg-gray-50 border border-arcadis-orange-300 text-arcadis-gray-800 text-lm rounded-lg focus:ring-arcadis-orange-700 focus:border-arcadis-orange-700 block w-full p-2.5 mt-4 mb-4"
        placeholder="Sorteervolgorde"
        :disabled="true"
      />
    </div>

    <div class="flex justify-between">
      <button class="btn mt-10" @click="handleSave">Add parameter</button>
      <button class="btn mt-10" @click="handleModal">Cancel</button>
    </div>
  </ModalComponent>
  <div v-if="customTestLevels.length">
    <DataTable
      :columns="[
        {
          label: 'id',
          field: 'id',
          editable: false,
          type: 'text'
        },
        {
          label: 'Component code',
          field: 'AsComponentCode',
          editable: false,
          type: 'text'
        },
        {
          label: 'Sorteervolgorde',
          field: 'CompoundOrder',
          editable: false,
          type: 'text'
        },
        {
          label: 'parameter',
          field: 'parameter',
          editable: false,
          type: 'text',
          clickable: true
        },
        {
          label: 'vluchtig',
          field: 'Volatile',
          editable: true,
          type: 'dropdown',
          data: {
            values: true_false,
            key: 'value',
            value: 'value',
            name: 'label'
          }
        },
        {
          label: 'carcinogeen',
          field: 'Carcinogenic',
          editable: true,
          type: 'dropdown',
          data: {
            values: true_false,
            key: 'value',
            value: 'value',
            name: 'label'
          }
        },
        {
          label: 'mutageen',
          field: 'Mutagenic',
          editable: true,
          type: 'dropdown',
          data: {
            values: true_false,
            key: 'value',
            value: 'value',
            name: 'label'
          }
        },
        {
          label: 'reprotoxisch',
          field: 'Reprotoxic',
          editable: true,
          type: 'dropdown',
          data: {
            values: true_false,
            key: 'value',
            value: 'value',
            name: 'label'
          }
        },
        {
          label: 'Eenheid',
          field: 'AsUnitCode',
          editable: true,
          type: 'dropdown',
          data: {
            values: sortedUnits,
            key: 'AsUnitCode',
            value: 'AsUnitCode',
            name: 'Description'
          }
        },

        {
          label: 'L1',
          field: 'L1',
          editable: true,
          type: 'text'
        },

        {
          label: 'L2',
          field: 'L2',
          editable: true,
          type: 'text'
        },

        {
          label: 'L3',
          field: 'L3',
          editable: true,
          type: 'text'
        },

        {
          label: 'L4',
          field: 'L4',
          editable: true,
          type: 'text'
        },

        {
          label: 'L5',
          field: 'L5',
          editable: true,
          type: 'text'
        },

        {
          label: 'L6',
          field: 'L6',
          editable: true,
          type: 'text'
        }
      ]"
      :rows="customTestLevels"
      :deletable="true"
      :moveable="true"
      @handleUpdate="handleUpdate"
      @handleDelete="handleDelete"
      @handleMoveRow="handleMoveRow"
      @handleRowClick="handleRowClick"
    />
  </div>
</template>
<script>
import DataTable from "@/components/DataTable.vue";
import { computed, watchEffect, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import CustomTestsLevelServices from "@/_services/CustomTestLevelServices";
import CustomtestServices from "@/_services/CustomtestServices";
import ParameterServices from "@/_services/ParameterServices";
import ModalComponent from "@/components/ModalComponent.vue";
import UnitServices from "@/_services/UnitServices";
import validateInput from "@/_utils/ValidateInput";
import LoadingComponent from "@/components/LoadingComponent.vue";

export default {
  name: "CustomTestLevelsComponent",
  components: { DataTable, ModalComponent, LoadingComponent },

  setup() {
    const store = useStore();
    const router = useRouter();
    const loadingState = computed(() => {
      return store.getters.getLoadingState;
    });

    const selectedTestId = ref("");
    const showModal = ref(false);
    const searchParam = ref("");
    const selectedParam = ref("");
    const searchParamError = ref(false);
    const selectedUnit = ref(59);
    const true_false = store.getters.getTrueFalse;

    const customTestsLevelServices = CustomTestsLevelServices();
    const customTestService = CustomtestServices();
    const parameterService = ParameterServices();
    const unitService = UnitServices();

    const L1 = ref(null);
    const L2 = ref(null);
    const L3 = ref(null);
    const L4 = ref(null);
    const L5 = ref(null);
    const L6 = ref(null);

    const editMode = computed(() => {
      return store.getters.getEditMode;
    });
    const units = computed(() => {
      return store.getters.getUnits;
    });

    const sortedUnits = computed(() => {
      if (units.value.length) {
        const selectionPriority = store.getters.getPriorityUnits;
        const sorted = [...units.value];
        sorted.sort((a, b) => {
          const countA = selectionPriority.includes(a.AsUnitCode) ? 1 : 0;
          const countB = selectionPriority.includes(b.AsUnitCode) ? 1 : 0;

          return countB - countA;
        });
        return sorted;
      }
      return null;
    });

    const parameters = computed(() => {
      return store.getters.getParameters;
    });

    const custom_test_id = computed(() => {
      return store.getters.getCustomTestId;
    });

    const CompoundOrder = computed(() => {
      return store.getters.getCustomTestLevels.length + 1;
    });

    const customTestLevels = computed(() => {
      return store.getters.getCustomTestLevels;
    });

    const customTests = computed(() => {
      return store.getters.getCustomTestsGenericData;
    });

    const filteredParameters = computed(() => {
      const arrayOfSelectedParameters = [...customTestLevels.value].map(
        (obj) => obj.AsComponentCode
      );
      return parameters.value.filter(
        (obj) => !arrayOfSelectedParameters.includes(obj.id)
      );
    });

    const disabledInputbox = computed(() => {
      if (!selectedParam.value) {
        return true;
      } else {
        return false;
      }
    });

    watch(
      () => selectedTestId.value,
      (newVal) => {
        if (newVal) {
          store.dispatch("saveCustomTestId", newVal);
        }
      }
    );

    watch(
      () => custom_test_id.value,
      (newVal) => {
        customTestsLevelServices.GET_LEVELS(newVal);
      }
    );

    watchEffect(() => {
      if (!customTestLevels.value.length) {
        try {
          customTestService.ALL_TESTS();
        } catch (error) {
          console.error(error);
        }
      }
    });

    watchEffect(() => {
      if (!units.value.length) {
        try {
          unitService.GET_ALL();
        } catch (error) {
          console.error(error);
        }
      }
    });

    const parameterError = ref(false);

    const validateParameter = () => {
      if (!selectedParam.value) {
        parameterError.value = true;
      } else {
        parameterError.value = false;
      }
    };

    const floatErrors = ref([
      ref(false),
      ref(false),
      ref(false),
      ref(false),
      ref(false),
      ref(false)
    ]);

    const handleModal = () => {
      showModal.value = !showModal.value;
      [L1, L2, L3, L4, L5, L6, selectedParam].forEach((input) => {
        input.value = null;
      });
      floatErrors.value.forEach((refValue) => (refValue.value = false));
    };

    const validateValueLevels = () => {
      const inputs = [L1, L2, L3, L4, L5, L6];

      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].value) {
          floatErrors.value[i].value = !validateInput(inputs[i].value);
        } else if (i === 0) {
          floatErrors.value[i].value = true; // Add error for L1
        }
      }
    };

    const handleSave = () => {
      validateParameter();
      validateValueLevels();

      const hasErrors = floatErrors.value.some((error) => error.value);

      if (!(parameterError.value || hasErrors)) {
        const payload = {
          L1: L1.value,
          L2: L2.value,
          L3: L3.value,
          L4: L4.value,
          L5: L5.value,
          L6: L6.value,
          AsUnitCode: selectedUnit.value,
          AsComponentCode: selectedParam.value,
          CompoundOrder: CompoundOrder.value,
          custom_assessment_id: selectedTestId.value
        };
        customTestsLevelServices.ADD(selectedTestId.value, payload);

        handleModal();
      }
    };

    const handleSearch = () => {
      if (searchParam.value) {
        parameterService.ALL_PARAMS(null, null, searchParam.value.trim());
      } else {
        searchParamError.value = true;
      }
    };

    const handleUpdate = (value) => {
      store.dispatch("updateCustomTestLevels", value);

      if (value.updated) {
        //let index = value.index;
        let payload = value.data;
        let custom_test_level_id = payload.id;
        console.log(payload);
        console.log("updating db");
        customTestsLevelServices.UPDATE(
          selectedTestId.value,
          custom_test_level_id,
          payload
        );
      }
    };

    const handleDelete = (value) => {
      let custom_test_level_id = value.data.id;
      customTestsLevelServices.DELETE(
        selectedTestId.value,
        custom_test_level_id,
        value
      );
    };

    const handleMoveRow = (value) => {
      store.dispatch("moveRow", value);
    };

    const handleRowClick = (value) => {
      let parameterId = value.AsComponentCode;
      store.dispatch("changeParameterFilterMode", true);
      store.dispatch("saveParameterFilterById", parameterId);
      router.push("/parameters");
    };

    onMounted(() => {
      if (custom_test_id.value) {
        try {
          customTestsLevelServices.GET_LEVELS(custom_test_id.value);
          selectedTestId.value = custom_test_id.value;
        } catch (error) {
          console.error(error);
        }
      }
    });

    return {
      customTestLevels,
      customTests,
      selectedTestId,
      custom_test_id,
      handleUpdate,
      handleModal,
      showModal,
      handleSearch,
      handleSave,
      searchParam,
      parameters,
      searchParamError,
      selectedParam,
      units,
      selectedUnit,
      L1,
      L2,
      L3,
      L4,
      L5,
      L6,
      CompoundOrder,
      filteredParameters,
      disabledInputbox,
      parameterError,
      floatErrors,
      sortedUnits,
      handleDelete,
      handleMoveRow,
      editMode,
      handleRowClick,
      loadingState,
      true_false
    };
  }
};
</script>
<style lang=""></style>
