<template>
  <div>
    <button @click="openFileSelector">Update geometry</button>
    <input
      type="file"
      ref="fileInput"
      class="hidden"
      accept=".zip"
      @change="handleFileSelect"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  emits: ["fileSelected"],
  setup(props, { emit }) {
    const fileInput = ref(null);
    const store = useStore();

    const openFileSelector = () => {
      fileInput.value.click();
    };

    const handleFileSelect = (event) => {
      console.log("fileupload");

      const file = event.target.files[0];

      store.dispatch("saveFile", file);

      emit("fileSelected");
    };

    return {
      fileInput,
      openFileSelector,
      handleFileSelect
    };
  }
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
