import makeRequest from "@/_services/ApiService";
import createRequestOptions from "./RequestOptions";
import store from "@/_store";

const CustomtestServices = () => {
  const requestOption = createRequestOptions();

  return {
    async ALL_TESTS() {
      store.dispatch("changeLoadingState");
      await makeRequest(requestOption.GET("v2/custom_tests/all"))
        .then((response) => {
          store.dispatch("saveCustomtests", response);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(store.dispatch("changeLoadingState"));
    },
    async NEW_TEST(payload) {
      await makeRequest(requestOption.POST("v2/custom_test/"), payload)
        .then((resp) => {
          store.dispatch("addCustomtest", resp);
        })
        .catch((err) => console.error(err))
        .finally(store.dispatch("changeLoadingState"));
    }
  };
};

export default CustomtestServices;
