import AccessTokenService from "./TokenService";

const baseURL = process.env.VUE_APP_API_BASE_URL;

const createRequestOptions = () => {
  const accessToken = AccessTokenService();
  const getRequestOptions = (url, method, body) => {
    return {
      method: method,
      url: baseURL + url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: body
    };
  };

  return {
    GET: (url, body) => getRequestOptions(url, "GET", body),
    PUT: (url, body) => getRequestOptions(url, "PUT", body),
    POST: (url, body) => getRequestOptions(url, "POST", body),
    PATCH: (url, body) => getRequestOptions(url, "PATCH", body),
    DELETE: (url, body) => getRequestOptions(url, "DELETE", body)
  };
};

export default createRequestOptions;
