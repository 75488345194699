const logs = {
  state: () => ({
    logs: {
      items: [],
      page: 0,
      pages: 0,
      size: 0,
      total: 0
    }
  }),
  getters: {
    getLogs: (state) => {
      if (state.logs.items) {
        const logsCopy = [...state.logs.items];
        logsCopy.sort((a, b) => b.id - a.id);
        return logsCopy;
      }
      return null;
    },
    getLogsPageSize: (state) => {
      return {
        page: state.logs.page,
        pages: state.logs.pages,
        size: state.logs.size,
        total: state.logs.total
      };
    }
  },
  mutations: {
    SAVE_LOGS(state, logs) {
      state.logs = logs;
    }
  }
};

export default logs;
