import axios from "axios";

const makeRequest = async (options) => {
  try {
    const response = await axios(options);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);
      console.error("Error response:", error.response);
    } else {
      console.error("Error:", error);
    }
    throw error; // Rethrow the error to be handled by the caller
  }
};

export default makeRequest;
