<template>
  <div class="flex flex-col items-center justify-center h-screen">
    <h1 class="text-4xl font-bold mb-4 italic">
      Welcome, what do you like to do?
    </h1>

    <div class="max-w-8xl mx-auto grid grid-cols-4 gap-4">
      <div
        v-for="info in routesWithAdditionalInfo"
        :key="info.meta.additionalInfo.title"
        class="bg-white rounded-lg p-4 flex flex-col"
        style="height: 100%"
      >
        <NavigationCard
          :title="info.meta.additionalInfo.title"
          :description="info.meta.additionalInfo.description"
          :componentName="info.name"
          :imageURL="info.meta.additionalInfo.imageURL"
          style="height: 100%"
        >
          <template #footer>
            <div class="flex justify-center mt-4">
              <router-link
                :to="{ name: props.componentName }"
                class="bg-blue-500 text-white rounded-lg px-4 py-2 text-center"
              >
                Go to page
              </router-link>
            </div>
          </template>
        </NavigationCard>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationCard from "@/components/NavigationCard.vue";
import { routesWithAdditionalInfo } from "@/_router";

export default {
  name: "HomeComponent",
  components: {
    NavigationCard
  },
  setup() {
    return {
      routesWithAdditionalInfo
    };
  }
};
</script>

<style></style>
