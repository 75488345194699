<template>
  <div>
    <table class="min-w-full bg-white border border-gray-300 mt-5 text-left">
      <thead>
        <tr>
          <th
            v-for="column in columns"
            :key="column.field"
            class="border-b bg-arcadis-orange-500 text-white font-bold text"
          >
            {{ column.label }}
          </th>
          <th
            class="py-2 px-4 border-b bg-arcadis-orange-500 text-white font-bold"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, index) in paginatedRows"
          :key="index"
          class="border-b py-2 px-4"
        >
          <td
            v-for="column in columns"
            :key="column.field"
            :class="{
              'cursor-pointer': clickable || (column.field && column.clickable)
            }"
            @click="
              (clickable || column.clickable) && !editMode
                ? handleRowClick(row, index)
                : null
            "
          >
            <template v-if="!editMode || !isEditingRow(index)">
              {{ getDisplayValue(row, column.field) }}
            </template>
            <template v-else>
              <template v-if="column.type === 'dropdown'">
                <select
                  v-model="editedRows[index][column.field]"
                  class="py-1 px-2 h-8 w-full font-bold disabled:opacity-50 bg-slate-200"
                  :disabled="!column.editable"
                >
                  <option
                    v-for="value in column.data.values"
                    :key="getColumnValue(value, column.data.key)"
                    :value="getColumnValue(value, column.data.value)"
                  >
                    {{ getColumnValue(value, column.data.name) }}
                  </option>
                </select>
              </template>
              <template v-else>
                <input
                  :type="column.type"
                  v-model="editedRows[index][column.field]"
                  class="py-1 px-2 h-8 w-full font-bold disabled:opacity-50 bg-slate-200"
                  :disabled="!column.editable"
                />
              </template>
            </template>
          </td>
          <td class="py-2 px-4">
            <div v-if="isAdmin || isEditor">
              <div v-if="!editMode || !isEditingRow(index)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 hover:cursor-pointer"
                  v-on:click="editMode ? null : editRow(index)"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                  />
                </svg>
              </div>
              <div v-else class="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 hover:cursor-pointer mr-2 text-arcadis-darkgreen"
                  @click="saveRow(index)"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12"
                  />
                </svg>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 mr-2 hover:cursor-pointer text-arcadis-orange-400"
                  @click="cancelEdit(index)"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 hover:cursor-pointer text-arcadis-red"
                  @click="handleDelete(index)"
                  v-if="deletable"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
                <div v-if="moveable">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 ml-2 hover:cursor-pointer"
                    @click="moveRow(index, 'up')"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 ml-2 hover:cursor-pointer"
                    @click="moveRow(index, 'down')"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mt-4" v-if="paginated && !filteredState">
      <button
        v-for="page in displayedButtons"
        :key="page"
        @click="changePage(page)"
        :class="{
          'bg-gray-500 text-white': currentPage === page,
          'bg-white text-gray-500': currentPage !== page
        }"
        class="px-2 py-1 mr-2 rounded"
      >
        {{ page }}
      </button>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "DataTable",

  props: {
    paginated: {
      type: Boolean,
      required: false,
      default: false
    },
    clickable: {
      type: Boolean,
      required: false,
      default: false
    },
    deletable: {
      type: Boolean,
      required: false,
      default: false
    },
    moveable: {
      type: Boolean,
      required: false,
      default: false
    },
    columns: {
      type: Array,
      required: true
    },
    rows: {
      type: Array,
      required: true
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1
    },
    pageSize: {
      type: Number,
      required: false,
      default: 25
    },
    totalPages: {
      type: Number,
      required: false,
      default: 1
    },
    maxDisplayedButtons: {
      type: Number,
      default: 5
    }
  },
  setup(props, { emit }) {
    const paginatedRows = computed(() => {
      return props.rows;
    });
    const store = useStore();

    const isAdmin = computed(() => {
      return store.getters.getAdminStatus;
    });
    const isEditor = computed(() => {
      return store.getters.getIsEditor;
    });

    const editMode = computed(() => {
      return store.getters.getEditMode;
    });

    const editingRowIndex = ref(-1);
    const oldValues = ref([]);
    const oldIndex = ref(-1);

    const getColumnValue = (obj, keyPath) => {
      const keys = keyPath.split(".");
      let value = obj;
      for (const key of keys) {
        value = value[key];
      }
      return value;
    };

    const getDisplayValue = (row, field) => {
      const column = props.columns.find((col) => col.field === field);

      if (column && column.type === "dropdown") {
        const value = row[field];

        const foundItem = column.data.values.find(
          (item) => item[column.data.value] === value
        );

        return foundItem ? foundItem[column.data.name] : "";
      }
      return row[field];
    };

    const isClickable = ref(props.clickable);

    const displayedButtons = computed(() => {
      const maxDisplayedButtons = props.maxDisplayedButtons;
      const totalPages = props.totalPages;
      const currentPage = props.currentPage;

      if (totalPages <= maxDisplayedButtons) {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
      }

      const halfDisplayedButtons = Math.floor(maxDisplayedButtons / 2);
      let startButton = currentPage - halfDisplayedButtons;
      let endButton = currentPage + halfDisplayedButtons;

      if (startButton <= 0) {
        startButton = 1;
        endButton = maxDisplayedButtons;
      } else if (endButton > totalPages) {
        endButton = totalPages;
        startButton = totalPages - maxDisplayedButtons + 1;
      }

      return Array.from(
        { length: endButton - startButton + 1 },
        (_, i) => startButton + i
      );
    });

    const editedRows = computed(() => {
      return [...props.rows];
    });

    const isEditingRow = computed(() => (index) => {
      return index === editingRowIndex.value;
    });

    const changePage = (page) => {
      emit("pageChange", page);
    };

    const handleRowClick = (row) => {
      emit("handleRowClick", row);
    };

    const handleDelete = (index) => {
      if (window.confirm("Are you sure you want to delete this value?")) {
        store.dispatch("changeEditMode", false);

        emit("handleDelete", {
          index: index,
          data: { ...editedRows.value[index] }
        });
      }
    };

    const moveRow = (index, direction) => {
      editingRowIndex.value =
        direction === "up"
          ? editingRowIndex.value - 1
          : editingRowIndex.value + 1;

      emit("handleMoveRow", { index, direction });
    };

    const editRow = (index) => {
      store.dispatch("changeEditMode", true);
      oldIndex.value = index;
      editingRowIndex.value = index;
      oldValues.value[index] = { ...editedRows.value[index] };
      oldValues.value[index].originalIndex = index;
    };

    const saveRow = (index) => {
      store.dispatch("changeEditMode", false);
      editingRowIndex.value = -1;

      emit("handleUpdate", {
        index: index,
        data: { ...editedRows.value[index] },
        updated: true
      });
    };

    const filteredState = computed(() => {
      return store.getters.getParameterFiltered;
    });

    const cancelEdit = (index) => {
      store.dispatch("changeEditMode", false);
      editingRowIndex.value = -1;
      const originalData = { ...oldValues.value[oldIndex.value] };
      emit("handleUpdate", {
        index: index,
        data: originalData,
        updated: false
      });
      oldValues.value = [];
    };

    return {
      paginatedRows,
      displayedButtons,
      changePage,
      editRow,
      saveRow,
      editMode,
      editingRowIndex,
      isEditingRow,
      cancelEdit,
      editedRows,
      isClickable,
      handleRowClick,
      handleDelete,
      getColumnValue,
      getDisplayValue,
      moveRow,
      oldValues,
      filteredState,
      isAdmin,
      isEditor
    };
  }
};
</script>

<style></style>
