const project = {
  state: () => ({
    project: {
      PrName: "",
      PrCode: "",
      PrDateLastChanged: "",
      license: "",
      int: "",
      id: "",
      PrID: "",
      LastImport: "",
      Geometry: false
    },
    projectNumber: "",
    file: ""
  }),
  getters: {
    getProjectData: (state) => {
      return state.project;
    },
    getProjectNumber: (state) => {
      return state.projectNumber;
    },
    getFile: (state) => {
      return state.file;
    },
    getLicense: (state) => {
      return state.project.license;
    }
  },
  actions: {
    saveProjectData({ commit }, project_data) {
      commit("setProjectData", project_data);
    },
    saveProjectNumber({ commit }, projectNumber) {
      commit("setProjectNumber", projectNumber);
    },
    saveFile({ commit }, file) {
      console.log("setting file", file);
      commit("SET_FILE", file);
    },
    deleteFile({ commit }) {
      commit("DELETE_FILE");
    },
    saveGeometry({ commit }, Geometry) {
      commit("UPDATE_GEOMETRY", Geometry);
    }
  },
  mutations: {
    setProjectNumber(state, projectNumber) {
      state.projectNumber = projectNumber;
    },
    setProjectData(state, projectData) {
      state.project = projectData;
    },
    SET_FILE(state, file) {
      state.file = file;
    },
    DELETE_FILE(state) {
      state.file = "";
    },
    UPDATE_GEOMETRY(state, Geometry) {
      state.project.Geometry = Geometry;
    },
    RESET_STATE(state) {
      (state.project = {
        PrName: "",
        PrCode: "",
        PrDateLastChanged: "",
        license: "",
        int: "",
        id: "",
        PrID: "",
        LastImport: "",
        Geometry: false
      }),
        (state.projectNumber = ""),
        (state.file = "");
    }
  }
};

export default project;
