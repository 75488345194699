<template>
  <div class="flex flex-col justify-center h-screen">
    <p class="text-center mb-4 text-xl italic">Use is for Arcadis only</p>
    <p class="text-center mb-4 text-xl italic">Please sign in</p>
    <p class="text-center text-xl italic">
      <a href="https://www.arcadis.com">Or visit our website</a>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
