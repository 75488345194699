<template>
  <div class="flex-full max-w-full mx-5 text-left">
    <div class="w-full overflow-x-auto">
      <DataTable
        :clickable="true"
        :columns="[
          {
            label: 'id',
            field: 'id',
            editable: false,
            type: 'text'
          },
          {
            label: 'Custom test',
            field: 'custom_test_name',
            editable: true,
            type: 'text'
          },
          {
            label: 'Beschrijving',
            field: 'custom_test_name_long',
            editable: true,
            type: 'text'
          },
          {
            label: 'Geldig van',
            field: 'valid_from',
            editable: true,
            type: 'text'
          },
          {
            label: 'Geldig tot',
            field: 'valid_to',
            editable: true,
            type: 'text'
          },
          {
            label: 'Inactief',
            field: 'inactive',
            editable: true,
            type: 'dropdown',
            data: {
              values: true_false,
              key: 'value',
              value: 'value',
              name: 'label'
            }
          }
        ]"
        :rows="customTests"
        :currentPage="1"
        :pageSize="25"
        :totalPages="1"
        @handleRowClick="handleRowClick"
      />
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/DataTable.vue";
import { useStore } from "vuex";
import CustomtestServices from "@/_services/CustomtestServices";
import { computed } from "@vue/reactivity";
import { useRouter } from "vue-router";

export default {
  name: "CustomTests",
  components: {
    DataTable
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const customTestService = CustomtestServices();

    const customTests = computed(() => {
      return store.getters.getCustomTestsGenericData;
    });

    const true_false = store.getters.getTrueFalse;

    const handleRowClick = (row) => {
      if (row) {
        store.dispatch("saveCustomTestId", row.id);
        router.push({ name: "CustomTestsLevels" });
      }
    };

    try {
      customTestService.ALL_TESTS();
    } catch (err) {
      console.log(err);
    }

    return {
      customTests,
      handleRowClick,
      true_false
    };
  }
};
</script>

<style></style>
