<template>
  <button class="btn" @click="logout">Logout</button>
</template>

<script>
import msalService from "../_auth/msalService.js";

export default {
  name: "SignOutButton",
  setup() {
    const logout = async () => {
      await msalService.logout().catch((e) => console.error(e));
    };

    return {
      logout
    };
  }
};
</script>

<style></style>
