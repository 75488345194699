import decodeToken from "./DecodeToken";

export default function isTokenValid(token) {
  try {
    if (!token) {
      return false;
    }

    const decodedToken = decodeToken(token);
    const currentTimeStamp = Math.floor(Date.now() / 1000);

    return (
      decodedToken.exp > currentTimeStamp &&
      decodedToken.nbf <= currentTimeStamp
    );
  } catch (error) {
    console.error("Token validation unsuccessful:", error);
    return false;
  }
}
