const parameters = {
  state: () => ({
    parameters: {
      items: [],
      page: 0,
      pages: 0,
      size: 0,
      total: 0
    },
    filterParameterByIdValue: ""
  }),
  getters: {
    getParameters: (state) => {
      return state.parameters.items;
    },
    getPageSize: (state) => {
      return {
        page: state.parameters.page,
        pages: state.parameters.pages,
        size: state.parameters.size,
        total: state.parameters.total
      };
    },
    getParameterByIndex: (state) => (index) => {
      return state.parameters.items[index];
    },
    getParameterByID: (state) => (id) => {
      return state.parameters.items.filter((item) => item.id === id);
    },
    getFilterParameterId: (state) => {
      return state.filterParameterByIdValue;
    }
  },
  actions: {
    saveParameters({ commit }, parameters) {
      commit("setParameters", parameters);
    },
    updateParameter({ commit }, parameter) {
      commit("setParameter", parameter);
    },
    saveParameterFilterById({ commit }, parameter) {
      commit("SET_PARAMETERFILTER", parameter);
    },
    saveParameter({ commit }, parameter) {
      commit("setIndividualParam", parameter);
    }
  },
  mutations: {
    setParameters(state, parameters) {
      state.parameters = parameters;
    },
    setIndividualParam(state, parameter) {
      state.parameters.items = [parameter];
    },

    setParameter(state, payload) {
      const { index, data } = payload;
      state.parameters.items[index] = data;
    },
    SET_PARAMETERFILTER(state, parameter) {
      state.filterParameterByIdValue = parameter;
    }
  }
};

export default parameters;
