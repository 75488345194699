import createRequestOptions from "./RequestOptions";
import makeRequest from "./ApiService";
import store from "@/_store";

const LogsServices = () => {
  const requestOption = createRequestOptions();

  return {
    async GET_ALL(page, size, filter) {
      page = page || 1;
      size = size || 25;
      filter = filter || "";

      try {
        await makeRequest(
          requestOption.GET(
            `v2/logs/all?page=${page}&size=${size}&filter=${filter}`
          )
        ).then((response) => {
          store.commit("SAVE_LOGS", response);
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  };
};

export default LogsServices;
