<template lang="">
  <div v-if="loadingState"><LoadingComponent /></div>
  <div class="mt-5">
    <div class="flex items-center">
      <InputBox
        id="Filter"
        inputType="text"
        placeholderText="Search value"
        v-model="filterText"
        @input="handleFilterText"
        @keyup.enter="handleSearch"
      />
      <button class="btn ml-2" @click="handleSearch">Search</button>
      <button v-if="filteredState" class="btn ml-2" @click="removeFilter">
        Remove filter
      </button>
    </div>
  </div>

  <div v-if="packagesList">
    <DataTable
      :paginated="true"
      :columns="[
        {
          label: 'id',
          field: 'id',
          editable: false,
          type: 'text'
        },
        {
          label: 'laboratorium',
          field: 'laboratory',
          editable: false,
          type: 'text'
        },
        {
          label: 'Package code (lab)',
          field: 'packagecode_id',
          editable: false,
          type: 'text'
        },
        {
          label: 'Omschrijving (lab)',
          field: 'package_name_laboratory',
          editable: false,
          type: 'text',
          clickable: false
        },
        {
          label: 'Omschrijving (Arcadis)',
          field: 'package_name_arcadis',
          editable: true,
          type: 'text',
          clickable: false
        },
        {
          label: 'Show in output',
          field: 'show_output',
          editable: true,
          type: 'dropdown',
          clickable: false,
          data: {
            values: true_false,
            key: 'value',
            value: 'value',
            name: 'label'
          }
        }
      ]"
      :rows="packagesList"
      :currentPage="pageAttributes.page"
      :pageSize="pageAttributes.size"
      :totalPages="pageAttributes.pages"
      @handleUpdate="handleUpdate"
      @pageChange="handlePageChange"
    />
  </div>
</template>
<script>
import DataTable from "@/components/DataTable.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import PackageServices from "@/_services/PackageServices";
import LoadingComponent from "@/components/LoadingComponent.vue";
import InputBox from "@/components/InputBox.vue";
export default {
  name: "PackagesComponent",
  components: { DataTable, LoadingComponent, InputBox },
  setup() {
    const store = useStore();
    const filterText = ref("");
    const currentPage = ref(1);

    const handleFilterText = (value) => {
      filterText.value = value.target.value;
    };

    const loadingState = computed(() => {
      return store.getters.getLoadingState;
    });

    const filteredState = computed(() => {
      return store.getters.getPackageFiltered;
    });

    const pageAttributes = computed(() => {
      return store.getters.getPackagePageSize;
    });

    const packagesList = computed(() => {
      return store.getters.getPackages;
    });

    const true_false = store.getters.getTrueFalse;

    const handlePageChange = (page) => {
      currentPage.value = page;
      packageService.ALL_PACKAGES(currentPage.value, "", filterText.value);
    };

    const handleSearch = () => {
      packageService.ALL_PACKAGES(currentPage.value, "", filterText.value);
    };

    const handleUpdate = (value) => {
      store.dispatch("updatePackage", value);

      if (value.updated) {
        const index = value.index;
        const payload = store.getters.getPackageByIndex(index);
        const package_id = payload.id;
        packageService.UPDATE_PACKAGE(package_id, payload);
      }
    };

    const packageService = PackageServices();
    try {
      packageService.ALL_PACKAGES(currentPage.value, "", filterText.value);
    } catch (err) {
      console.error(err);
    }

    const removeFilter = () => {
      store.dispatch("changePackageFilterMode");
      packageService.ALL_PACKAGES();
    };

    return {
      packagesList,
      loadingState,
      true_false,
      handleUpdate,
      filterText,
      currentPage,
      handlePageChange,
      pageAttributes,
      removeFilter,
      filteredState,
      handleFilterText,
      handleSearch
    };
  }
};
</script>
<style lang=""></style>
