import makeRequest from "@/_services/ApiService";
import createRequestOptions from "./RequestOptions";
import store from "@/_store";

const SwaggerSerice = () => {
  const requestOption = createRequestOptions();

  return {
    async SWAGGER_DOCS() {
      await makeRequest(requestOption.GET("openapi.json"))
        .then((response) => {
          store.dispatch("saveApiDefinition", response);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
};

export default SwaggerSerice;
