<template lang="">
  <div v-if="isLoggedIn">
    <div v-if="!adminAccess || (adminAccess && isAdmin)">
      <router-link
        :to="{ name: routeName }"
        class="text-arcadis-gray-800 hover:font-bold"
        :class="{ 'font-bold': $route.name === routeName }"
        exact-active-class="font-bold"
        >{{ linkName }}</router-link
      >
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "NavBarLink",
  props: {
    routeName: {
      type: String,
      required: true
    },
    linkName: {
      type: String,
      required: true
    },
    adminAccess: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => {
      return store.getters.getLoggedInStatus;
    });
    const isAdmin = computed(() => {
      return store.getters.getAdminStatus;
    });

    return {
      isLoggedIn,
      isAdmin
    };
  }
};
</script>
<style lang=""></style>
