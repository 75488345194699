<template lang="">
  <div v-if="logs">
    <DataTable
      :paginated="true"
      :deletable="false"
      :columns="[
        {
          label: 'id',
          field: 'id',
          editable: false,
          type: 'text'
        },
        {
          label: 'created at',
          field: 'created_at',
          editable: false,
          type: 'text'
        },
        {
          label: 'level',
          field: 'level',
          editable: false,
          type: 'text'
        },
        {
          label: 'module',
          field: 'module',
          editable: false,
          type: 'text'
        },
        {
          label: 'ip',
          field: 'ip',
          editable: false,
          type: 'text'
        },
        {
          label: 'path',
          field: 'path',
          editable: false,
          type: 'text'
        },

        {
          label: 'message',
          field: 'message',
          editable: false,
          type: 'text'
        }
      ]"
      :rows="logs"
      :currentPage="pageAttributes.page"
      :pageSize="pageAttributes.size"
      :totalPages="pageAttributes.pages"
      @pageChange="handlePageChange"
    />
  </div>
</template>
<script>
import DataTable from "@/components/DataTable.vue";
import LogsServices from "@/_services/LogsServices";
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "LogsComponent",
  components: { DataTable },
  setup() {
    const store = useStore();
    const logServices = LogsServices();
    const logs = computed(() => {
      return store.getters.getLogs;
    });
    const currentPage = ref(1);
    const filterText = ref("");

    const pageAttributes = computed(() => {
      return store.getters.getLogsPageSize;
    });

    const handlePageChange = (page) => {
      currentPage.value = page;
      logServices.GET_ALL(currentPage.value, "", filterText.value);
    };

    try {
      logServices.GET_ALL();
    } catch (error) {
      console.error(error);
    }

    return {
      logs,
      pageAttributes,
      handlePageChange
    };
  }
};
</script>
<style lang=""></style>
