const units = {
  state: () => ({
    units: [],
    selectionPriority: [14, 59, 54]
  }),
  getters: {
    getUnits: (state) => {
      return state.units;
    },

    getPriorityUnits: (state) => {
      return state.selectionPriority;
    }
  },
  actions: {
    saveUnits({ commit }, units) {
      commit("setUnits", units);
    }
  },
  mutations: {
    setUnits(state, units) {
      state.units = units;
    }
  }
};

export default units;
