import { PublicClientApplication } from "@azure/msal-browser";
import msalConfig from "./msalConfig";
import store from "@/_store";
import router from "@/_router";
import decodeToken from "@/_utils/DecodeToken";

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.enableAccountStorageEvents();

export default {
  async login() {
    const loginRequest = {
      scopes: ["api://489c8c78-2317-4d7a-8421-6cfbc9a38da5/user_impersonation"] // Add the required scopes for your app
    };
    console.log("logging in");

    await msalInstance.initialize();
    console.log("MSAL initialized successfully");

    try {
      console.log("calling token provider");
      const tokenResponse = await msalInstance.loginPopup(loginRequest);

      if (tokenResponse) {
        const decodedToken = decodeToken(tokenResponse["accessToken"]);
        console.log(decodedToken["roles"]);
        store.commit("SETUSERROLE", decodedToken["roles"]);

        if (decodedToken["roles"].includes("Admin")) {
          console.log("user is admin");
          store.commit("SETADMIN", true);
        }

        console.log("storing token");
        store.dispatch("saveAccount", tokenResponse);
        store.dispatch("saveLogInStatus", true);
        router.push("/");
      } else {
        console.log("no token received, emptying account");
        store.dispatch("saveAccount", null);
        store.dispatch("saveLogInStatus", false);
        store.commit("SETUSERROLE", null);
        store.commit("SETADMIN", false);
      }
    } catch (error) {
      console.error(error);
    }
  },

  async logout() {
    router.push("/login");
    store.dispatch("saveAccount", "");
    store.dispatch("saveLogInStatus", false);
    store.dispatch("SETUSERROLE", null);
    store.dispatch("SETADMIN", false);
    await msalInstance.logoutPopup().catch((e) => console.error(e));
  },

  async getToken() {
    const account = store.getters.getAccount;
    msalInstance.setActiveAccount(account);
    const tokenRequest = {
      scopes: ["api://489c8c78-2317-4d7a-8421-6cfbc9a38da5/user_impersonation"] // Add the required scopes for your API
    };

    try {
      if (store.getters.getLoggedInStatus) {
        const response = await msalInstance.acquireTokenSilent(tokenRequest);

        if (response) {
          console.log("storing accessToken");
          store.dispatch("saveAccount", response);
        }
      } else {
        console.error("not logged in");
      }
    } catch (error) {
      console.error(error);
    }
  }
};
