import { createStore } from "vuex";
import sessionStoragePlugin from "@/_plugins/sessionStoragePlugin";
import project from "./modules/project";
import user from "./modules/user";
import parameters from "./modules/parameter";
import api_definition from "./modules/api_definition";
import custom_tests from "./modules/custom_tests";
import custom_tests_levels from "./modules/custom_test_levels";
import units from "./modules/units";
import application_state from "./modules/application_state";
import constants_library from "./modules/constants_library";
import packages from "./modules/packages";
import non_regulated_subs from "./modules/non_regulated_subs";
import logs from "./modules/logs";

const store = createStore({
  plugins: [sessionStoragePlugin],

  modules: {
    project,
    user,
    api_definition,
    parameters,
    custom_tests,
    custom_tests_levels,
    units,
    application_state,
    constants_library,
    packages,
    non_regulated_subs,
    logs
  }
});

export default store;
