import makeRequest from "@/_services/ApiService";
import createRequestOptions from "./RequestOptions";
import store from "@/_store";
import { download } from "@/_utils/DownloadFile";
import { cleanupString } from "@/_utils/CleanupString";
// import axios from "axios";

const ProjectServices = () => {
  const requestOption = createRequestOptions();

  return {
    async METADATA(payload, update) {
      try {
        store.dispatch("changeLoadingState");
        await makeRequest(
          requestOption.POST(`v2/projects/metadata?update=${update}`, payload)
        ).then((response) => {
          store.dispatch("saveProjectData", response.data[0]);
          store.dispatch("changeLoadingState");
        });
      } catch (error) {
        store.dispatch("changeLoadingState");
        console.error(error);
        throw error;
      }
    },
    async UPDATE(payload, license) {
      store.dispatch("changeLoadingState");
      await makeRequest(
        requestOption.POST(
          `v2/projects/update_project?license=${license}`,
          payload
        )
      )
        .then((response) => {
          store.dispatch("changeLoadingState");
          alert(response);
        })
        .catch((err) => {
          alert(err);
          console.error(err);
          store.dispatch("changeLoadingState");
        });
    },

    async DOWNLOAD_TERRAINDEX(payload, license, appcode) {
      store.dispatch("changeLoadingState");
      await makeRequest(
        requestOption.POST(
          `v2/terraindex/terra_project?license=${license}&appcode=${appcode}`,
          payload
        )
      )
        .then((response) => {
          const filename = cleanupString(payload.PrCode) + ".json";

          download(JSON.stringify(response), filename, "text/plain");
          store.dispatch("changeLoadingState");
        })
        .catch((err) => {
          console.error(err);
          store.dispatch("changeLoadingState");
        });
    },
    async UPLOAD_FILE(uuid, payload) {
      store.dispatch("changeLoadingState");
      await makeRequest(
        requestOption.PUT(`v2/projects/${uuid}/location`, payload)
      )
        .then((resp) => {
          console.log(resp);
          alert("Geometry updated");
          store.dispatch("saveGeometry", true);
          store.dispatch("changeLoadingState");
        })
        .catch((err) => {
          console.error(err);
          store.dispatch("changeLoadingState");
        });
    },
    async DELETE_PROJECT(uuid, reload) {
      reload = reload | "true";
      store.dispatch("changeLoadingState");
      console.log(`v2/projects/${uuid}/delete?reload=${reload}`);
      await makeRequest(
        requestOption.DELETE(`v2/projects/${uuid}/delete?reload=${reload}`)
      )
        .then((response) => {
          if (reload) {
            store.dispatch("saveProjectData", response.data[0]);
            alert("Project has been deleted and reloaded");
          } else {
            store.commit("RESET_STATE");
            alert("Project has been deleted and not updated");
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(store.dispatch("changeLoadingState"));
    }
  };
};

export default ProjectServices;
