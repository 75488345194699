<template>
  <div class="flex-full max-w-full mx-5 text-left">
    <div class="mt-5">
      <div class="flex items-center">
        <InputBox
          id="Filter"
          inputType="text"
          placeholderText="Search value"
          @input="handleFilterText"
        />
        <button class="btn ml-2" @click="handleSearch">Search</button>
        <button v-if="filteredState" class="btn ml-2" @click="removeFilter">
          Remove filter
        </button>
      </div>
      <div class="flex items-center" v-if="parameters">
        <div class="w-full overflow-x-auto">
          <DataTable
            :paginated="true"
            :columns="[
              { label: 'id', field: 'id', editable: false, type: 'text' },
              {
                label: 'Omschrijving',
                field: 'Omschrijving',
                editable: false,
                type: 'text'
              },
              {
                label: 'Acroniem',
                field: 'Acronym',
                editable: true,
                type: 'text'
              },
              {
                label: 'Synoniem',
                field: 'Synonym',
                editable: true,
                type: 'text'
              },
              { label: 'Code', field: 'Code', editable: false, type: 'text' },
              {
                label: 'Carcinogeen',
                field: 'Carcinogenic',
                editable: true,
                type: 'dropdown',
                data: {
                  values: true_false,
                  key: 'value',
                  value: 'value',
                  name: 'label'
                }
              },
              {
                label: 'Mutageen',
                field: 'Mutagenic',
                editable: true,
                type: 'dropdown',
                data: {
                  values: true_false,
                  key: 'value',
                  value: 'value',
                  name: 'label'
                }
              },
              {
                label: 'Reprotoxisch',
                field: 'Reprotoxic',
                editable: true,
                type: 'dropdown',
                data: {
                  values: true_false,
                  key: 'value',
                  value: 'value',
                  name: 'label'
                }
              },
              {
                label: 'Vluchtig',
                field: 'Volatile',
                editable: true,
                type: 'dropdown',
                data: {
                  values: true_false,
                  key: 'value',
                  value: 'value',
                  name: 'label'
                }
              },
              {
                label: 'CAS nummer',
                field: 'CAS_nr',
                editable: true,
                type: 'text'
              },
              {
                label: 'EU nummer',
                field: 'EU_nr',
                editable: true,
                type: 'text'
              }
            ]"
            :rows="parameters"
            :currentPage="pageAttributes.page"
            :pageSize="pageAttributes.size"
            :totalPages="pageAttributes.pages"
            @pageChange="handlePageChange"
            @handleUpdate="handleUpdate"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParameterServices from "@/_services/ParameterServices";
import DataTable from "@/components/DataTable.vue";
import { computed, onMounted, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import InputBox from "@/components/InputBox.vue";

export default {
  name: "ParameterComponent",
  components: {
    DataTable,
    InputBox
  },
  setup() {
    const store = useStore();
    const filterText = ref("");
    const currentPage = ref(1);
    const parameterService = ParameterServices();
    const filteredState = computed(() => {
      return store.getters.getParameterFiltered;
    });

    const handleFilterText = (value) => {
      store.dispatch("changeParameterFilterMode", false);
      filterText.value = value.target.value;
    };

    const true_false = store.getters.getTrueFalse;

    const handleUpdate = (value) => {
      store.dispatch("updateParameter", value);

      if (value.updated) {
        const index = value.index;
        const payload = store.getters.getParameterByIndex(index);
        const param_id = payload.id;
        parameterService.UPDATE(param_id, payload);
      }
    };

    const handlePageChange = (page) => {
      currentPage.value = page;
      parameterService.ALL_PARAMS(currentPage.value, "", filterText.value);
    };

    const handleSearch = () => {
      parameterService.ALL_PARAMS(currentPage.value, "", filterText.value);
    };

    const pageAttributes = computed(() => {
      return store.getters.getPageSize;
    });

    const removeFilter = () => {
      store.dispatch("changeParameterFilterMode", false);
      parameterService.ALL_PARAMS();
    };

    const parameterFilterId = computed(() => {
      return store.getters.getFilterParameterId;
    });

    const filteredParameters = computed(() => {
      return store.getters.getParameterByID(parameterFilterId.value);
    });

    const parameters = computed(() => {
      return store.getters.getParameters;
    });

    watchEffect(() => {
      if (filteredState.value) {
        parameterService.GET_PARAM_BY_ID(parameterFilterId.value);
      } else {
        parameterService.ALL_PARAMS();
      }
    });

    onMounted(() => {});

    return {
      parameters,
      pageAttributes,
      handlePageChange,
      filterText,
      handleFilterText,
      handleSearch,
      handleUpdate,
      true_false,
      filteredState,
      filteredParameters,
      parameterFilterId,
      removeFilter
    };
  }
};
</script>

<style></style>
