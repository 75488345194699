<template>
  <div class="flex items-center">
    <label
      :for="id"
      class="block mr-2 mb-2 text-lm font-large font-bold text-arcadis-gray-800 dark:text-white whitespace-nowrap"
      >{{ labelName }}</label
    >
    <input
      :type="inputType"
      :id="id"
      class="bg-gray-50 border border-arcadis-orange-300 text-arcadis-gray-800 text-lm rounded-lg focus:ring-arcadis-orange-700 focus:border-arcadis-orange-700 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      :placeholder="placeholderText"
      v-model="initValue"
      required
      @input="emitValue(value)"
      @keyup.enter="emitValue(value)"
    />
  </div>
</template>

<script>
//import { ref } from "vue";

import { ref } from "vue";

export default {
  name: "InputBox",
  props: {
    labelName: {
      required: false
    },
    inputType: {
      required: true
    },
    id: {
      required: true
    },
    placeholderText: {
      required: false
    },
    initial_value: {
      required: false
    }
  },
  setup(props, { emit }) {
    const initValue = ref(props.initial_value);
    const emitValue = (value) => {
      emit("update:value", value);
    };

    return { emitValue, initValue };
  }
};
</script>
