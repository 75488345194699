import makeRequest from "@/_services/ApiService";
import createRequestOptions from "./RequestOptions";
import store from "@/_store";

const ParameterServices = () => {
  const requestOption = createRequestOptions();

  return {
    async ALL_PARAMS(page, size, filter) {
      page = page || 1;
      size = size || 25;
      filter = filter || "";

      await makeRequest(
        requestOption.GET(
          `v2/parameters/all?page=${page}&size=${size}&filter=${filter}`
        )
      )
        .then((response) => {
          store.dispatch("saveParameters", response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async UPDATE(param_id, payload) {
      await makeRequest(
        requestOption.PATCH(
          `v2/parameters/parameter/${param_id}/update/`,
          payload
        )
      )
        .then(() => console.log("parameter updated"))
        .catch((err) => console.err(err));
    },
    async GET_PARAM_BY_ID(param_id) {
      await makeRequest(
        requestOption.GET(`v2/parameters/parameter/${param_id}`)
      )
        .then((resp) => {
          console.log(resp);
          store.dispatch("saveParameter", resp);
        })
        .catch((err) => console.error(err));
    }
  };
};

export default ParameterServices;
