<template>
  <div
    class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:cursor-pointer hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
    @click="handleRouting"
  >
    <img
      :src="require('@/assets/img/' + props.imageURL)"
      alt="Card Image"
      class="h-48 w-full object-cover"
    />
    <div class="p-4">
      <h3 class="text-xl font-bold">{{ props.title }}</h3>
      <p class="text-gray-600">{{ props.description }}</p>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "NavigationCard",
  props: {
    title: {
      required: true
    },
    description: {
      required: false
    },
    imageURL: {
      required: false
    },
    componentName: {
      required: true
    }
  },

  setup(props) {
    const route = useRouter();

    const fullImageURL = "@assets/img/" + props.imageURL;

    const handleRouting = () => {
      route.push({ name: props.componentName });
    };

    return {
      route,
      props,
      fullImageURL,
      handleRouting
    };
  }
};
</script>

<style></style>
