import makeRequest from "@/_services/ApiService";
import createRequestOptions from "./RequestOptions";
import store from "@/_store";

const PackageServices = () => {
  const requestOption = createRequestOptions();

  return {
    async ALL_PACKAGES(page, size, filter) {
      page = page || 1;
      size = size || 25;
      filter = filter || "";
      store.dispatch("changeLoadingState");
      await makeRequest(
        requestOption.GET(
          `v2/packages/all?page=${page}&size=${size}&filter=${filter}`
        )
      )
        .then((response) => {
          console.log(response);
          store.dispatch("savePackages", response);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(store.dispatch("changeLoadingState"));
    },
    async UPDATE_PACKAGE(package_id, payload) {
      store.dispatch("changeLoadingState");
      await makeRequest(
        requestOption.PATCH(`v2/packages/${package_id}/update`, payload)
      )
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(store.dispatch("changeLoadingState"));
    }
  };
};

export default PackageServices;
