const non_regulated_subs = {
  state: () => ({
    non_regulated_subs: []
  }),
  getters: {
    getNonRegulatedSub: (state) => {
      return state.non_regulated_subs;
    }
  },
  actions: {
    saveNonRegulatedSubs({ commit }, payload) {
      commit("SET_NONREGULATED_SUBS", payload);
    },
    addNonRegulatedSubs({ commit }, payload) {
      commit("ADD_NONREGULATED_SUB", payload);
    },
    deleteNonRegulatedSubs({ commit }, index) {
      commit("DELETE_NONREGULATED_SUB", index);
    }
  },
  mutations: {
    SET_NONREGULATED_SUBS(state, payload) {
      state.non_regulated_subs = payload;
    },
    ADD_NONREGULATED_SUB(state, payload) {
      state.non_regulated_subs.push(payload);
    },
    DELETE_NONREGULATED_SUB(state, index) {
      state.non_regulated_subs.splice(index, 1);
    }
  }
};

export default non_regulated_subs;
