import { createRouter, createWebHistory } from "vue-router";
import HomeComponent from "../_pages/HomeComponent.vue";
import LoginComponent from "../_pages/LoginComponent.vue";
import SwaggerComponent from "../_pages/SwaggerComponent.vue";
import ProjectsComponent from "../_pages/ProjectsComponent.vue";
import ParameterComponent from "@/_pages/ParameterComponent.vue";
import CustomTestComponent from "@/_pages/CustomTestComponent.vue";
import CustomTestLevelsComponent from "@/_pages/CustomTestLevelsComponent";
import PackagesComponent from "@/_pages/PackagesComponent";
import NonRegulatedSubsComponent from "@/_pages/NonRegulatedSubsComponent";
import LogsComponent from "@/_pages/LogsComponent.vue";
import store from "@/_store";
import isTokenValid from "@/_utils/CheckTokenExpired";
import msalService from "@/_auth/msalService";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeComponent,
    skip: true,
    meta: {
      isAuth: true,
      adminAccess: false
    }
  },
  {
    path: "/Login",
    name: "Login",
    skip: true,
    component: LoginComponent,
    meta: {
      isAuth: false,
      adminAccess: false
    }
  },
  {
    path: "/Swagger",
    name: "Swagger",
    component: SwaggerComponent,
    meta: {
      isAuth: true,
      adminAccess: false,
      additionalInfo: {
        title: "API Documentation",
        description:
          "The API Documentation page provides information and instructions on how to interact with and utilize APIs",
        imageURL: "api.webp"
      }
    }
  },
  {
    path: "/Projects",
    name: "Projects",
    component: ProjectsComponent,
    meta: {
      isAuth: true,
      adminAccess: true,
      additionalInfo: {
        title: "Projects",
        description:
          "On the projects page, you can find detailed information of each project in the TerraMirror Database, perform updates and download JSON from Terraindex",
        imageURL: "terraindex.png"
      }
    }
  },
  {
    path: "/Parameters",
    name: "Parameters",
    component: ParameterComponent,
    meta: {
      isAuth: true,
      adminAccess: false,
      additionalInfo: {
        title: "Parameters",
        description:
          "On the parameters page, you can find detailed information on parameters.",
        imageURL: "parameters.jpg"
      }
    }
  },
  {
    path: "/CustomTests",
    name: "CustomTests",
    component: CustomTestComponent,
    screenName: "Custom Test Levels",
    meta: {
      isAuth: true,
      adminAccess: false,
      additionalInfo: {
        title: "Custom Tests",
        description:
          "On the customtests page, you can find additional information on the different custom tests.",
        imageURL: "custom_test.jpg"
      }
    }
  },
  {
    path: "/CustomTestsLevels",
    name: "CustomTestsLevels",
    component: CustomTestLevelsComponent,
    screenName: "Custom Test Components",
    meta: {
      isAuth: true,
      adminAccess: false,
      additionalInfo: {
        title: "Custom test levels",
        description:
          "On this page, you can find the different levels for the custom tests.",
        imageURL: "levels.jpg"
      }
    }
  },
  {
    path: "/Packages",
    name: "Packages",
    screenName: "Analytical Packages",
    component: PackagesComponent,
    meta: {
      isAuth: true,
      adminAccess: false,
      additionalInfo: {
        title: "Analytical packages",
        description:
          "On this page, you can find and change the analytical packages description.",
        imageURL: "Agrolab-Group.jpg"
      }
    }
  },
  {
    path: "/nonregulatedSubs",
    name: "NonRegulatedSubsComponent",
    component: NonRegulatedSubsComponent,
    screenName: "Non Regulated Substances",
    meta: {
      isAuth: true,
      adminAccess: false,
      additionalInfo: {
        title: "Non Regulated Substances",
        description:
          "On this page, you can manage the non regulated substances that should be reported.",
        imageURL: "NonRegulatedSubs.jpg"
      }
    }
  },
  {
    path: "/logs",
    name: "LogsComponent",
    component: LogsComponent,
    screenName: "Logs",
    meta: {
      isAuth: true,
      adminAccess: true
    }
  },
  {
    path: "/:path(.*)",
    name: "CatchOther",
    skip: true,
    component: HomeComponent,
    meta: {
      isAuth: true,
      adminAccess: true
    }
  }
];

const router = createRouter({
  history: createWebHistory("/"),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.isAuth)) {
    if (!store.getters.getLoggedInStatus) {
      next("/login");
      return;
    }

    if (!isTokenValid(store.getters.getIdToken)) {
      msalService.getToken();
    }
    next(); // Proceed to the next route
  } else {
    next(); // Proceed to the next route
  }
});

export const routesWithAdditionalInfo = routes.filter(
  (route) => route.meta && route.meta.additionalInfo
);

export const routesWithMetaInfo = routes.filter(
  (route) => route.meta && route.path && !route.skip
);

export default router;
