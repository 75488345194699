<template>
  <div v-if="loadingState"><LoadingComponent /></div>
  <div>
    <NavBarComponent />
    <RouterView />
  </div>
</template>

<script>
import NavBarComponent from "./components/Navbar.vue";
import LoadingComponent from "./components/LoadingComponent.vue";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "App",
  components: {
    NavBarComponent,
    LoadingComponent
  },
  setup() {
    const store = useStore();
    const loadingState = computed(() => {
      return store.getters.getLoadingState;
    });

    return { loadingState };
  }
};
</script>

<style></style>
