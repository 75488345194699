<template lang="">
  <div
    class="overlay fixed top-0 left-0 w-full h-full bg-arcadis-orange-200 opacity-50 z-50"
  ></div>
</template>
<script>
import {} from "vue";
import {} from "vuex";
export default {
  name: "LoadingComponent",

  setup() {
    return {};
  }
};
</script>
<style lang=""></style>
