import makeRequest from "@/_services/ApiService";
import createRequestOptions from "./RequestOptions";

const UserService = () => {
  const requestOption = createRequestOptions();

  return {
    async GET_ALL() {
      try {
        const response = await makeRequest(requestOption.GET("v2/users/all"));
        return response;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async CHANGE_PWD(userID, payload) {
      try {
        const response = await makeRequest(
          requestOption.PATCH(`v2/users/${userID}/update_pwd`, payload)
        );
        return response;
      } catch (err) {
        console.error(err);
      }
    },
    async ADD_USER(payload) {
      try {
        const response = await makeRequest(
          requestOption.POST("v2/users/add", payload)
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async UPDATE_USER(userID, payload) {
      try {
        const response = await makeRequest(
          requestOption.PATCH(`v2/users/${userID}/update`, payload)
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async DELETE_USER(userID) {
      try {
        const response = await makeRequest(
          requestOption.DELETE(`v2/users/${userID}/delete`)
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    }
  };
};

export default UserService;
